// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-social-impact-blog-js": () => import("./../../../src/pages/social-impact/blog.js" /* webpackChunkName: "component---src-pages-social-impact-blog-js" */),
  "component---src-pages-social-impact-featured-js": () => import("./../../../src/pages/social-impact/featured.js" /* webpackChunkName: "component---src-pages-social-impact-featured-js" */),
  "component---src-pages-social-impact-index-js": () => import("./../../../src/pages/social-impact/index.js" /* webpackChunkName: "component---src-pages-social-impact-index-js" */),
  "component---src-pages-social-impact-monday-motivation-js": () => import("./../../../src/pages/social-impact/monday-motivation.js" /* webpackChunkName: "component---src-pages-social-impact-monday-motivation-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

