import "./src/styles/global.css";

// export const onInitialClientRender = () => {
//   document.onbeforeunload = () => {
//     document.getElementById("___loader").style.display = "none";
//   };
//   document.onloadstart = () => {
//     document.getElementById("___loader").style.display = "flex";
//   };
// };
